import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as api from 'apis';
import _ from 'lodash/fp';
import useAuth from 'hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import usePlayList from 'hooks/usePlayList';
import useEstimate from 'hooks/useEstimate';
import useContract from 'hooks/useContract';
import MediaPlayListWrapper from 'components/MediaPlayListWrapper/MediaPlayListWrapper';

export default function MediaPlayListContainer() {
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [tempMaxCount, setTempMaxCount] = useState(8);
  const navigator = useNavigate();
  const {
    playList,
    playLoop,
    playType,
    playId,
    playMute,
    selectedPlay,
    playStart,
    playVolume,
    handleChangePlayData,
    currentPlayTime,
    playDuration,
    shufflePlayList,
  } = usePlayList();

  const { estimate } = useEstimate();

  const { contract } = useContract();

  const { isUnlimitedPlaylist, handleResetAuthData, checkAuthPlay } = useAuth();

  const getGrade = () => {
    if (estimate && estimate.status === 'CONFIRM') {
      if (
        contract &&
        (contract.status === 'FINISH' || contract.status === 'SCHEDULED')
      ) {
        if (estimate.solutionType === 'PREMIUM') {
          return '프리미엄';
        } else if (estimate.solutionType === 'STANDARD') {
          return '스탠다드';
        } else if (estimate.solutionType === 'BASIC') {
          return '베이직';
        } else {
          return '';
        }
      }
    }
    return '';
  };

  const grade = getGrade();

  const moving = useRef(null);
  const popup = useRef(null);

  const playTypeThrottle = useRef(null);
  const nextPlayThrottle = useRef(null);
  const prevPlayThrottle = useRef(null);
  const volumeThrottle = useRef(null);
  const currentPlayTimeThrottle = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        let res = null;

        if (process.env.REACT_APP_TEMP_BLOCK === 'YES') {
          res = await api.getMyPlayList(false);
        } else {
          res = await api.getMyAllPlayList();
        }

        console.log('mylist res ', res);

        handleChangePlayData({
          playList: res?.data || [],
          playId:
            (res &&
              res?.data?.length > 0 &&
              res?.data?.filter((el) => el.isAcutalPlay).length > 0 &&
              res?.data?.filter((el) => el.isAcutalPlay)[0].id) ||
            null,
          playStart: true, // 플레이중
          playLoop: false, // 반복
          playMute: false, // 음소거
          playVolume: 100,
          currentPlayTime: null,
          selectedPlay: null,
          playDuration: '300', // 5분 Default
          realPlayList: [],
          playType: 'Ordering',
        });
        setIsFetching(true);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (isUnlimitedPlaylist) return setIsInit(true);
    (async () => {
      try {
        const res = await Promise.all([
          api.getMyEstimate().catch(() => {}),
          api.getMyContract().catch(() => {}),
        ]);
        const estimate = res[0]?.data;
        const contract = res[1]?.data;
        if (estimate && estimate?.status === 'CONFIRM') {
          if (
            contract &&
            (contract?.status === 'FINISH' || contract?.status === 'SCHEDULED')
          ) {
            if (estimate?.solutionType === 'PREMIUM') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'STANDARD') {
              setIsInit(true);
            } else if (estimate?.solutionType === 'BASIC') {
              setIsInit(true);
            } else {
              checkAuthPlay(estimate, contract);
              alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
              navigator('/');
            }
          } else {
            checkAuthPlay(estimate, contract);
            alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
            navigator('/');
          }
        } else {
          checkAuthPlay(estimate, contract);
          alert('콘텐츠 플레이는 솔루션 계약 완료 후 이용가능하십니다 : )');
          navigator('/');
        }
      } catch (err) {}
    })();
  }, [navigator, setIsInit, isUnlimitedPlaylist]);

  const handleClick = async (e, content) => {
    e.preventDefault();
    handleChangePlayData({ selectedPlay: content });
  };

  const handleClickLike = async (e, contentId) => {
    if (!contentId) {
      return;
    }
    e.stopPropagation();
    const tempData = _.cloneDeep(playList);
    let index;
    tempData.some((el, i) => {
      if (el.id === contentId) {
        index = i;
      }
      return el.id === contentId;
    });

    try {
      if (tempData[index].liked) {
        await api.cancelLikeContent(contentId);
      } else {
        await api.likeContent(contentId);
      }
      tempData[index].liked = !tempData[index].liked;
      handleChangePlayData({ playList: tempData || [] });
    } catch (err) {
      console.error(err);
    }
  };

  // const handleRemove = async (e, index, id) => {
  //     e.stopPropagation();
  //     // const ment = popup?.current ? '' : '';
  //     if (window.confirm(`플레이리스트에서 삭제하시겠습니까?`)) {
  //         try {
  //             const wishPlayList = JSON.parse(localStorage.getItem('wishPlayList')) || [];
  //             const wishData = [...wishPlayList.filter(item => item.id !== id)];
  //             const data = [...playList.filter(item => item.id !== id)];;
  //             await api.postMyPlayList({
  //                 content_ids: [...data.map(el => el.id)]
  //             });
  //             localStorage.setItem('wishPlayList', JSON.stringify(wishData));
  //             const playList2 = data.length > 0 ? data : [];
  //             const playId2 = data.length > 0 ? data[0].id : null;
  //             handleChangePlayData({
  //                 playList: playList2,
  //                 playId: playId2,
  //             });
  //             popup?.current?.postMessage({
  //                 mode: 'play',
  //                 playList: playList2,
  //                 playId: playId2,
  //                 playStart,
  //                 removed: true,
  //             }, process.env.REACT_APP_ORIGIN);
  //         } catch (err) {
  //             console.error(err);
  //         }
  //     }
  // }

  const handleUp = async () => {
    if (popup?.current) {
      alert('영상 플레이 종료 후 순서를 변경해주세요 : )');
      return;
    }

    if (moving.current) {
      clearTimeout(moving.current);
    }
    if (!selectedPlay) return;
    let selectedIndex;
    playList.some((el, index) => {
      if (el.id === selectedPlay.id) {
        selectedIndex = index;
      }
      return el.id === selectedPlay.id;
    });
    const changePlayList = changeArrayOrder(playList, selectedIndex, -1);
    const realPlayList =
      changePlayList.filter((el) => el.isAcutalPlay)[0] || null;
    handleChangePlayData({
      playList: changePlayList || [],
      playId: realPlayList?.id || null,
      currentPlayTime:
        playList.filter((el) => el.isAcutalPlay)[0]?.id !== realPlayList?.id
          ? 0
          : currentPlayTime,
    });
    try {
      moving.current = setTimeout(async () => {
        await api.patchMyPlayList({
          content_ids: [...changePlayList.map((el) => el.id)],
        });
        moving.current = null;
        // popup?.current?.postMessage({ mode: 'play', playList: changePlayList || [], playId: currentId, playStart }, process.env.REACT_APP_ORIGIN);
      }, 300);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDown = async () => {
    if (popup?.current) {
      alert('영상 플레이 종료 후 순서를 변경해주세요 : )');
      return;
    }

    if (moving.current) {
      clearTimeout(moving.current);
    }
    if (!selectedPlay) return;
    let selectedIndex;
    playList.some((el, index) => {
      if (el.id === selectedPlay.id) {
        selectedIndex = index;
      }
      return el.id === selectedPlay.id;
    });
    const changePlayList = changeArrayOrder(playList, selectedIndex, 1);
    const realPlayList =
      changePlayList.filter((el) => el.isAcutalPlay)[0] || null;
    handleChangePlayData({
      playList: changePlayList || [],
      playId: realPlayList?.id || null,
      currentPlayTime:
        playList.filter((el) => el.isAcutalPlay)[0]?.id !== realPlayList?.id
          ? 0
          : currentPlayTime,
    });
    try {
      moving.current = setTimeout(async () => {
        await api.patchMyPlayList({
          content_ids: [...changePlayList.map((el) => el.id)],
        });
        moving.current = null;
        // popup?.current?.postMessage({ mode: 'play', playList: changePlayList || [], playId: currentId, playStart }, process.env.REACT_APP_ORIGIN);
      }, 300);
    } catch (err) {}
  };

  const changeArrayOrder = function (list, targetIdx, moveValue) {
    // 배열값이 없는 경우 나가기
    if (list.length < 0) return;

    // 이동할 index 값을 변수에 선언
    const newPosition = targetIdx + moveValue;

    // 이동할 값이 0보다 작거나 최대값을 벗어나는 경우 종료
    if (newPosition < 0 || newPosition >= list.length) return list;

    // 임의의 변수를 하나 만들고 배열 값 저장
    const tempList = _.cloneDeep(list);

    // 옮길 대상을 target 변수에 저장하기
    const target = tempList.splice(targetIdx, 1)[0];

    // 새로운 위치에 옮길 대상을 추가하기
    tempList.splice(newPosition, 0, target);
    return tempList;
  };

  const handlePlayOrdering = (currentVideo, isDirect) => {
    // handleChangePlayData({ playType: 'Ordering' || [] });
    if (playType === 'Shuffle') {
      const vList = shufflePlayList.filter((el) => el.isAcutalPlay);
      if (!vList || vList.length === 0) {
        alert('영상 플레이를 최소 1개 포함해주세요 : )');
        return;
      }
    } else {
      const vList = playList.filter((el) => el.isAcutalPlay);
      if (!vList || vList.length === 0) {
        alert('영상 플레이를 최소 1개 포함해주세요 : )');
        return;
      }
      // if (tempMaxCount && vList?.length > tempMaxCount) {
      //   alert(`영상 플레이는 최대 ${tempMaxCount}개까지만 가능하십니다 : )`);
      //   return;
      // }
    }

    if (!popup?.current) {
      popup.current = window.open(
        isDirect ? '/play-list/play?isDirect=true' : '/play-list/play',
        'targetWindow',
        '',
        false,
      );
    } else {
      alert('이미 영상 플레이창에서 플레이 중입니다.');
    }
  };

  // const handlePlayShuffle = () => {
  //     const data = { playType: 'Shuffle', playLoop: false };
  //     handleChangePlayData(data);
  //     popup?.current?.postMessage({ ...data, mode: 'play', }, process.env.REACT_APP_ORIGIN);
  // }

  const handlePlayLoop = (video) => {
    if (!video) {
      return;
    }
    const data = { playLoop: !playLoop };
    handleChangePlayData(data);
    popup?.current?.postMessage(
      { playLoop: !playLoop, mode: 'play' },
      process.env.REACT_APP_ORIGIN,
    );
    // if (!playLoop) {
    //     popup?.current?.postMessage({ playLoop: !playLoop, playList: [video, video, video, video, video] }, process.env.REACT_APP_ORIGIN);
    // } else {
    //     let selectedIndex;
    //     playList.some((el, index) => {
    //         if (el.id === video.id) {
    //             selectedIndex = index;
    //         }
    //         return el.id === video.id;
    //     });

    //     popup?.current?.postMessage({ playIndex: selectedIndex + 1 === playList.length ? 0 : selectedIndex + 1, playLoop: !playLoop, playList }, process.env.REACT_APP_ORIGIN);
    // }
  };

  const shuffle = (array) => {
    for (let index = array.length - 1; index > 0; index--) {
      // 무작위 index 값을 만든다. (0 이상의 배열 길이 값)
      const randomPosition = Math.floor(Math.random() * (index + 1));

      // 임시로 원본 값을 저장하고, randomPosition을 사용해 배열 요소를 섞는다.
      const temporary = array[index];
      array[index] = array[randomPosition];
      array[randomPosition] = temporary;
    }
  };

  const handlePlayType = (video) => {
    if (popup?.current) {
      alert(
        '영상 플레이 종료 후 플레이 타입을(순서재생, 랜덤재생) 변경해주세요 : )',
      );
      return;
    }

    if (!video) {
      return;
    }

    if (playTypeThrottle.current) {
      clearTimeout(playTypeThrottle.current);
    }

    playTypeThrottle.current = setTimeout(async () => {
      if (playType === 'Shuffle') {
        // Ordering
        let selectedIndex;
        playList
          .filter((el) => el.isAcutalPlay)
          .some((el, index) => {
            if (el.id === video.id) {
              selectedIndex = index;
            }
            return el.id === video.id;
          });
        const data = {
          playType: playType === 'Shuffle' ? 'Ordering' : 'Shuffle',
          playLoop: playType !== 'Shuffle' ? false : playLoop,
          shufflePlayList: [],
        };
        handleChangePlayData(data);
        popup?.current?.postMessage(
          {
            playType: playType === 'Shuffle' ? 'Ordering' : 'Shuffle',
            playId: video.id,
            playList: playList.filter((el) => el.isAcutalPlay),
            playStart,
            playLoop: playType !== 'Shuffle' ? false : playLoop,
            mode: 'play',
          },
          process.env.REACT_APP_ORIGIN,
        );
      } else {
        // Shuffle
        const tempData = _.cloneDeep(
          playList
            .filter((el) => el.isAcutalPlay)
            .filter((el) => el.id !== video.id),
        );
        shuffle(tempData);
        const data = {
          playType: playType === 'Shuffle' ? 'Ordering' : 'Shuffle',
          playLoop: playType !== 'Shuffle' ? false : playLoop,
          shufflePlayList: [video, ...tempData],
        };
        handleChangePlayData(data);

        popup?.current?.postMessage(
          {
            playType: playType === 'Shuffle' ? 'Ordering' : 'Shuffle',
            playId: video.id,
            playList: [video, ...tempData],
            playStart,
            playLoop: playType !== 'Shuffle' ? false : playLoop,
            mode: 'play',
          },
          process.env.REACT_APP_ORIGIN,
        );
      }
    }, 300);
  };

  const hanldeNextPlay = useCallback(
    (video) => {
      if (!video) {
        return;
      }

      if (nextPlayThrottle.current) {
        clearTimeout(nextPlayThrottle.current);
      }

      nextPlayThrottle.current = setTimeout(async () => {
        if (playList.filter((el) => el.isAcutalPlay).length < 1) return;
        if (playType === 'Shuffle') {
          let randomIndex;
          shufflePlayList
            .filter((el) => el.isAcutalPlay)
            .some((el, index) => {
              if (el.id === video.id) {
                randomIndex = index;
              }
              return el.id === video.id;
            });
          const data = {
            currentPlayTime: 0,
            playId:
              randomIndex + 1 ===
              playList.filter((el) => el.isAcutalPlay).length
                ? shufflePlayList.filter((el) => el.isAcutalPlay)[0].id
                : shufflePlayList.filter((el) => el.isAcutalPlay)[
                    randomIndex + 1
                  ].id,
          };

          handleChangePlayData(data);
          popup?.current?.postMessage(
            {
              playMute,
              playVolume,
              playLoop,
              playNext: true,
              playStart,
              currentPlayTime: 0,
              playId:
                randomIndex + 1 ===
                playList.filter((el) => el.isAcutalPlay)?.length
                  ? shufflePlayList.filter((el) => el.isAcutalPlay)[0]?.id
                  : shufflePlayList.filter((el) => el.isAcutalPlay)[
                      randomIndex + 1
                    ]?.id,
              playList: shufflePlayList.filter((el) => el.isAcutalPlay),
              mode: 'play',
            },
            process.env.REACT_APP_ORIGIN,
          );
        } else {
          let selectedIndex;
          playList
            .filter((el) => el.isAcutalPlay)
            .some((el, index) => {
              if (el.id === video.id) {
                selectedIndex = index;
              }
              return el.id === video.id;
            });

          const data = {
            currentPlayTime: 0,
            playId:
              selectedIndex + 1 ===
              playList.filter((el) => el.isAcutalPlay).length
                ? playList.filter((el) => el.isAcutalPlay)[0]?.id
                : playList.filter((el) => el.isAcutalPlay)[selectedIndex + 1]
                    ?.id,
          };
          handleChangePlayData(data);
          popup?.current?.postMessage(
            {
              playMute,
              playVolume,
              playLoop,
              playNext: true,
              playStart,
              currentPlayTime: 0,
              playId:
                selectedIndex + 1 ===
                playList.filter((el) => el.isAcutalPlay).length
                  ? playList.filter((el) => el.isAcutalPlay)[0]?.id
                  : playList.filter((el) => el.isAcutalPlay)[selectedIndex + 1]
                      ?.id,
              playList: playList.filter((el) => el.isAcutalPlay),
              mode: 'play',
            },
            process.env.REACT_APP_ORIGIN,
          );
        }
      }, 200);
    },
    [
      nextPlayThrottle,
      playList,
      playMute,
      playVolume,
      playLoop,
      playType,
      playId,
      selectedPlay,
      playStart,
      handleChangePlayData,
      currentPlayTime,
      playDuration,
      shufflePlayList,
    ],
  );

  const hanldePrevPlay = useCallback(
    (video) => {
      if (!video) {
        return;
      }

      if (prevPlayThrottle.current) {
        clearTimeout(prevPlayThrottle.current);
      }

      prevPlayThrottle.current = setTimeout(async () => {
        if (playList.filter((el) => el.isAcutalPlay).length < 1) return;
        if (playType === 'Shuffle') {
          let randomIndex;
          shufflePlayList
            .filter((el) => el.isAcutalPlay)
            .some((el, index) => {
              if (el.id === video.id) {
                randomIndex = index;
              }
              return el.id === video.id;
            });

          const data = {
            currentPlayTime: 0,
            playId:
              randomIndex - 1 === -1
                ? shufflePlayList.filter((el) => el.isAcutalPlay)[
                    shufflePlayList.filter((el) => el.isAcutalPlay).length - 1
                  ]?.id
                : shufflePlayList.filter((el) => el.isAcutalPlay)[
                    randomIndex - 1
                  ]?.id,
          };
          handleChangePlayData(data);
          popup?.current?.postMessage(
            {
              playMute,
              playVolume,
              playLoop,
              playPrev: true,
              playStart,
              currentPlayTime: 0,
              playId:
                randomIndex - 1 === -1
                  ? shufflePlayList.filter((el) => el.isAcutalPlay)[
                      shufflePlayList.filter((el) => el.isAcutalPlay).length - 1
                    ]?.id
                  : shufflePlayList.filter((el) => el.isAcutalPlay)[
                      randomIndex - 1
                    ]?.id,
              playList: shufflePlayList.filter((el) => el.isAcutalPlay),
              mode: 'play',
            },
            process.env.REACT_APP_ORIGIN,
          );
        } else {
          let selectedIndex;
          playList
            .filter((el) => el.isAcutalPlay)
            .some((el, index) => {
              if (el.id === video.id) {
                selectedIndex = index;
              }
              return el.id === video.id;
            });

          const data = {
            currentPlayTime: 0,
            playId:
              selectedIndex - 1 === -1
                ? playList.filter((el) => el.isAcutalPlay)[
                    playList.filter((el) => el.isAcutalPlay).length - 1
                  ]?.id
                : playList.filter((el) => el.isAcutalPlay)[selectedIndex - 1]
                    ?.id,
          };
          handleChangePlayData(data);
          popup?.current?.postMessage(
            {
              playMute,
              playVolume,
              playLoop,
              playPrev: true,
              playStart,
              currentPlayTime: 0,
              playId:
                selectedIndex - 1 === -1
                  ? playList.filter((el) => el.isAcutalPlay)[
                      playList.filter((el) => el.isAcutalPlay).length - 1
                    ]?.id
                  : playList.filter((el) => el.isAcutalPlay)[selectedIndex - 1]
                      ?.id,
              playList: playList.filter((el) => el.isAcutalPlay),
              mode: 'play',
            },
            process.env.REACT_APP_ORIGIN,
          );
        }
      }, 200);
    },
    [
      prevPlayThrottle,
      playList,
      playMute,
      playVolume,
      playLoop,
      playType,
      playId,
      selectedPlay,
      playStart,
      handleChangePlayData,
      currentPlayTime,
      playDuration,
      shufflePlayList,
    ],
  );

  const receiveMessage = useCallback(
    async (e) => {
      // 도메인 체크 X
      // if (e.origin !== process.env.REACT_APP_ORIGIN) return;
      if (e.data.hasOwnProperty('currentPlayTime')) {
        handleChangePlayData({ currentPlayTime: e.data.currentPlayTime });
      }

      if (e.data.hasOwnProperty('playId')) {
        handleChangePlayData({ playId: e.data.playId });
      }

      if (e.data.hasOwnProperty('windowStatus')) {
        console.log('e.data.windowStatus ', e.data.windowStatus);
        if (e.data.windowStatus === 'ready') {
          popup?.current?.postMessage(
            {
              mode: 'play',
              isInit: true,
              playType,
              playList:
                playType === 'Shuffle'
                  ? shufflePlayList.filter((el) => el.isAcutalPlay)
                  : playList.filter((el) => el.isAcutalPlay),
              playId,
              playStart,
              playLoop,
              playMute,
              playVolume,
              currentPlayTime,
              playDuration,
            },
            process.env.REACT_APP_ORIGIN,
          );
        }

        if (e.data.windowStatus === 'refresh') {
          popup?.current?.close();
          popup.current = null;
          window.location.reload();
        }

        if (e.data.windowStatus === 'close') {
          popup?.current?.close();
          popup.current = null;
          window.location.reload();
        }

        if (e.data.windowStatus === 'logout') {
          popup?.current?.close();
          popup.current = null;
          await localStorage.clear();
          await sessionStorage.clear();
          handleResetAuthData();
          window.location.reload();
        }
      }
    },
    [
      playType,
      playList,
      playId,
      playStart,
      playLoop,
      playMute,
      playVolume,
      currentPlayTime,
      playDuration,
      shufflePlayList,
    ],
  );

  const handleVolumnChange = (e, video) => {
    if (!video) {
      return;
    }
    if (volumeThrottle.current) {
      clearTimeout(volumeThrottle.current);
    }

    const value = e.target.value;

    volumeThrottle.current = setTimeout(async () => {
      const data = { playMute: !(Number(value) > 0), playVolume: value };
      handleChangePlayData(data);
      popup?.current?.postMessage(
        { ...data, mode: 'play' },
        process.env.REACT_APP_ORIGIN,
      );
    }, 300);
  };

  const handlePlayTimeChange = (e, videoLength) => {
    if (currentPlayTimeThrottle.current) {
      clearTimeout(currentPlayTimeThrottle.current);
    }

    currentPlayTimeThrottle.current = setTimeout(async () => {
      const data = { currentPlayTime: (e.target.value * videoLength) / 100 };
      handleChangePlayData(data);
      popup?.current?.postMessage(
        { ...data, mode: 'play' },
        process.env.REACT_APP_ORIGIN,
      );
    }, 300);
  };

  const handlePlayMute = (video) => {
    if (!video) {
      return;
    }
    const data = { playMute: !playMute, playVolume: !playMute ? 0 : 100 };
    handleChangePlayData({
      playMute: !playMute,
      playVolume: !playMute ? 0 : 100,
    });
    popup?.current?.postMessage(
      { ...data, mode: 'play' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  const handlePlayStart = (video) => {
    if (!video) {
      return;
    }
    const data = { playStart: !playStart };
    handleChangePlayData(data);
    popup?.current?.postMessage(
      { ...data, mode: 'play' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  const handlePlayDuration = (e) => {
    if (!e.target.value) return alert('플레이 지속시간을 선택해주세요 : )');
    if (popup?.current) {
      alert('영상 플레이 종료 후 플레이 지속시간을 변경해주세요 : )');
      return;
    }

    const data = { playDuration: e.target.value };
    handleChangePlayData(data);
    popup?.current?.postMessage(
      { ...data, mode: 'play' },
      process.env.REACT_APP_ORIGIN,
    );
  };

  const handleAgree = async (e, el) => {
    e.stopPropagation();
    e.preventDefault();

    if (popup?.current) {
      alert('영상 플레이 종료 후 영상 플레이 포함여부를 변경해주세요 : )');
      return;
    }

    try {
      const res = await api.updateActualContent(el.id);

      const tempData = _.cloneDeep(playList);
      let changeIndex;
      tempData.filter((item, index) => {
        if (item.id === el.id) {
          changeIndex = index;
        }
        return item.id === el.id;
      });

      if (changeIndex === 0 || changeIndex) {
        tempData[changeIndex].isAcutalPlay =
          !tempData[changeIndex].isAcutalPlay;
      }

      const shuffleTempData = _.cloneDeep(shufflePlayList);
      let shuffleChangeIndex;
      shuffleTempData.filter((item, index) => {
        if (item.id === el.id) {
          shuffleChangeIndex = index;
        }
        return item.id === el.id;
      });

      if (shuffleChangeIndex === 0 || shuffleChangeIndex) {
        shuffleTempData[shuffleChangeIndex].isAcutalPlay =
          !shuffleTempData[shuffleChangeIndex].isAcutalPlay;
      }

      const data = {
        playList: tempData,
        shufflePlayList: shuffleTempData,
        playId:
          playType === 'Shuffle'
            ? shuffleTempData.filter((el) => el.isAcutalPlay)[0]?.id || null
            : tempData.filter((el) => el.isAcutalPlay)[0]?.id || null,
      };
      handleChangePlayData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAllAgree = (e) => {};

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => window.removeEventListener('message', receiveMessage, false);
  }, [
    playType,
    playList,
    playId,
    playStart,
    playLoop,
    playMute,
    playVolume,
    currentPlayTime,
    playDuration,
    shufflePlayList,
  ]);

  window.onbeforeunload = function () {
    popup?.current?.close();
    popup.current = null;
  };

  const handleMoveClick = () => {
    popup?.current?.close();
    popup.current = null;
  };

  if (!isInit) return <></>;

  return (
    <MediaPlayListWrapper
      playList={playList}
      playId={playId}
      selectedPlay={selectedPlay}
      onClick={handleClick}
      // handleRemove={handleRemove}
      handleClickLike={handleClickLike}
      handleUp={handleUp}
      handleDown={handleDown}
      handlePlayOrdering={handlePlayOrdering}
      // handlePlayShuffle={handlePlayShuffle}
      handlePlayLoop={handlePlayLoop}
      handlePlayType={handlePlayType}
      playLoop={playLoop}
      playType={playType}
      playMute={playMute}
      playVolume={playVolume}
      handleVolumnChange={handleVolumnChange}
      currentPlayTime={currentPlayTime}
      handlePlayTimeChange={handlePlayTimeChange}
      handlePlayMute={handlePlayMute}
      playStart={playStart}
      handlePlayStart={handlePlayStart}
      hanldeNextPlay={hanldeNextPlay}
      hanldePrevPlay={hanldePrevPlay}
      playDuration={playDuration}
      handlePlayDuration={handlePlayDuration}
      handleMoveClick={handleMoveClick}
      grade={grade}
      handleAgree={handleAgree}
      handleAllAgree={handleAllAgree}
      setTempMaxCount={setTempMaxCount}
      tempMaxCount={tempMaxCount}
      isFetching={isFetching}
    />
  );
}
